var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "content-select"
  }, [_c('div', {
    staticClass: "content-select-item"
  }, [_c('label', {
    attrs: {
      "for": "year"
    }
  }, [_vm._v("Year")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.year,
      expression: "year"
    }],
    attrs: {
      "name": "year",
      "id": "year"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.year = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1year"
    }
  }, [_vm._v("1 Year")]), _c('option', {
    attrs: {
      "value": "10year"
    }
  }, [_vm._v("10 Year")]), _c('option', {
    attrs: {
      "value": "100year"
    }
  }, [_vm._v("100 Year")])])]), _c('div', {
    staticClass: "content-select-item"
  }, [_c('label', {
    attrs: {
      "for": "season"
    }
  }, [_vm._v("Season")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.season,
      expression: "season"
    }],
    attrs: {
      "name": "season",
      "id": "season"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.season = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "winter"
    }
  }, [_vm._v("Winter")]), _c('option', {
    attrs: {
      "value": "spring"
    }
  }, [_vm._v("Spring")]), _c('option', {
    attrs: {
      "value": "summer"
    }
  }, [_vm._v("Summer")]), _c('option', {
    attrs: {
      "value": "autumn"
    }
  }, [_vm._v("Autumn")])])])]), _c('div', {
    staticClass: "content-image",
    staticStyle: {
      "display": "flex",
      "justify-content": "center"
    },
    attrs: {
      "id": "my-strictly-unique-vue-upload-multiple-image"
    }
  }, [_c('vue-upload-multiple-image', {
    attrs: {
      "data-images": _vm.images,
      "dragText": "Drag images (multiple)",
      "browseText": "(or) Select",
      "primaryText": "Default",
      "markIsPrimaryText": "Set as default",
      "popupText": "This image will be displayed as default",
      "dropText": "Drop your files here...",
      "maxImage": "100",
      "idUpload": "myIdUpload",
      "editUpload": "myIdEdit"
    },
    on: {
      "upload-success": _vm.uploadImageSuccess,
      "before-remove": _vm.beforeRemove,
      "edit-image": _vm.editImage
    }
  })], 1)]), _c('button', {
    staticClass: "button",
    on: {
      "click": _vm.uploadImage
    }
  }, [_vm._v("Upload")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }