<template>
  <div class="block">
    <div class="content">
      <div class="content-select">
        <div class="content-select-item">
          <label for="year">Year</label>
          <select v-model="year" name="year" id="year">
            <option value="1year">1 Year</option>
            <option value="10year">10 Year</option>
            <option value="100year">100 Year</option>
          </select>
        </div>
        <div class="content-select-item">
          <label for="season">Season</label>
          <select v-model="season" name="season" id="season">
            <option value="winter">Winter</option>
            <option value="spring">Spring</option>
            <option value="summer">Summer</option>
            <option value="autumn">Autumn</option>
          </select>
        </div>
      </div>
      <div class="content-image" id="my-strictly-unique-vue-upload-multiple-image" style="display: flex; justify-content: center;">
        <vue-upload-multiple-image
            @upload-success="uploadImageSuccess"
            @before-remove="beforeRemove"
            @edit-image="editImage"
            :data-images="images"
            dragText="Drag images (multiple)"
            browseText="(or) Select"
            primaryText="Default"
            markIsPrimaryText="Set as default"
            popupText="This image will be displayed as default"
            dropText="Drop your files here..."
            maxImage="100"
            idUpload="myIdUpload"
            editUpload="myIdEdit"
        ></vue-upload-multiple-image>
      </div>
    </div>
    <button class="button" @click="uploadImage">Upload</button>
  </div>
</template>

<script>
  // import arweaveUploder from '../arweave';
  import VueUploadMultipleImage from 'vue-upload-multiple-image'
  export default {
    name: "UploadImage",
    components: {
      VueUploadMultipleImage,
    },
    data() {
      return {
        images: [],
        dataImages: [],
        image: '',
        season: 'winter',
        year: '1year',
      }
    },
    methods: {
      uploadImageSuccess(formData, index, fileList) {
        console.log('data', formData, index, fileList)
        this.dataImages = {...fileList}
      },
      beforeRemove (index, done, fileList) {
        console.log('index', index, fileList)
        var r = confirm("remove image")
        if (r == true) {
          done()
        }
      },
      editImage (formData, index, fileList) {
        console.log('edit data', formData, index, fileList)
      },
      async uploadImage () {
        // await Object.entries(this.dataImages).map(async([item, value]) => {
        //   const year = this.year
        //   const season = this.season
        //   const file = value.path
        //   const id = +item + 1
        //   await arweaveUploder(year, season, id, file)
        // })
        this.images = []
        this.$toasted.success('Something went wrong!').goAway(1500)
      }
      // uploadImage  (e) {
      //   const vm = this
      //   function getBase64(file) {
      //     var reader = new FileReader();
      //     reader.readAsDataURL(file);
      //     reader.onload = async function () {
      //       console.log(reader.result)
      //       vm.image = await arweave(reader.result)
      //       return reader.result
      //     };
      //     reader.onerror = function (error) {
      //       console.log('Error: ', error);
      //     };
      //     return reader
      //   }
      //   getBase64(e)
      // },
    }
  }
</script>

<style scoped lang="scss">
  .block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }
  .button {
    background: #004EDA;
    padding: 8px 12px;
    border-radius: 5px;
    color: white;
    width: 100px;
  }
  .content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 160px;
  }
  .content-select {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }
  .content-select-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10px;


    select{
      margin-top: 10px;
      width: 100%;
      padding:6px 15px;
      border-radius: 5px;
      border: 2px solid #2c3e50 ;
    }
  }
  #my-strictly-unique-vue-upload-multiple-image {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  h1, h2 {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
